import React, { FC, useEffect, useRef } from 'react'
import Jazzicon from 'jazzicon'
import { useActiveWeb3React } from 'hooks/index'

import styles from './index.module.scss'

const Identicon: FC = () => {
  const ref = useRef<HTMLDivElement>()

  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = ''
      ref.current.appendChild(Jazzicon(24, parseInt(account.slice(2, 10), 16)))
    }
  }, [account])

  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
  return <div ref={ref as any} className={styles.icon} />
}

export default Identicon
