import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  Store,
} from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import application from './application'
import transactions from './transactions'
import user from './user'
// import prices from './prices'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'transactions'],
}

const store: Store = configureStore({
  reducer: persistReducer(
    persistConfig,
    combineReducers({
      application,
      transactions,
      user,
      // prices,
    })
  ),
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
      immutableCheck: false,
    }),
  ],
  devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
