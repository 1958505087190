import { useWeb3React } from '@web3-react/core'
import { StargazerConnector } from 'connectors/Stargazer'

export const useWeb3ReactWithDAG = () => {
  const { connector, library, chainId, account, active, error, activate } =
    useWeb3React()

  let dagAccount: string | undefined = undefined
  if (connector instanceof StargazerConnector) {
    if (connector && (connector as any).constellationAccounts?.length) {
      dagAccount = (connector as any).constellationAccounts[0]
    }
  }

  return {
    connector,
    library,
    chainId,
    account,
    dagAccount,
    active,
    error,
    activate,
  }
}
