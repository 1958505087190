import { ReactComponent as Dashboard } from 'assets/images/svgs/dashboard.svg';
import { ReactComponent as Staking } from 'assets/images/svgs/staking.svg';
import { ReactComponent as Proposal } from 'assets/images/svgs/proposal.svg';
import { ReactComponent as RightArrow } from 'assets/images/svgs/rocket.svg';
import config from '../../config';

export const menuItems = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    Icon: Dashboard,
    className: 'path',
    link: config.routes.dashboard,
    submenu: [],
  },
  {
    id: 'staking1',
    name: '$JAM Staking Pools',
    Icon: Staking,
    className: 'stroke',
    link: config.routes.jamStaking,
    submenu: [],
  },
  {
    id: 'proposal',
    name: 'Proposals',
    className: 'path',
    link: config.routes.proposals,
    Icon: Proposal,
    submenu: [],
  },
  {
    id: 'ai',
    name: 'AI Tools',
    className: 'path',
    link: config.routes.ai,
    Icon: RightArrow,
    submenu: [],
  },
  // {
  //   id: 'jamshop',
  //   name: '$JAM Shop',
  //   className: 'path',
  //   link: config.routes.jamshop,
  //   Icon: RightArrow,
  //   submenu: [],
  // },
];
