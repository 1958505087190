import React from 'react'
import ReactDOM from 'react-dom'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { BrowserRouter } from 'react-router-dom'
import { ChainId, Config, DAppProvider } from '@usedapp/core'
import getLibrary from 'utils/getLibrary'

import { NetworkContextName } from './constants'
import { UserProvider } from './providers/user'
import StoreProvider from './providers/store'
import App from './containers/App'
import reportWebVitals from './reportWebVitals'
import './index.css'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

document.documentElement.className = ''
document.documentElement.classList.add(`theme--dark`)

const dappConfig: Config = {
  readOnlyChainId:
    process.env.NODE_ENV === 'production' ? ChainId.Mainnet : ChainId.Ropsten,
  readOnlyUrls: {
    [ChainId.Mainnet]: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA}`,
    [ChainId.Ropsten]: `https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA}`,
  },
  supportedChains: [ChainId.Mainnet, ChainId.Ropsten],
  pollingInterval: 1000,
}
ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <DAppProvider config={dappConfig}>
          <StoreProvider>
            <UserProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </UserProvider>
          </StoreProvider>
        </DAppProvider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
