import Amplify, { Auth as _Auth } from 'aws-amplify'

// No types available for this package so use require here
const MockAuth = require('mock-amplify-auth')

MockAuth.currentAuthenticatedUser = async (): Promise<any> => {
  const session = await MockAuth.currentSession()

  return {
    ...session,
    getUsername: () => {
      return session?.idToken?.payload?.sub
    },
    getSignInUserSession: () => {
      return {
        getAccessToken: () => {
          return {
            getJwtToken: () => {
              session?.idToken?.jwtToken
            },
          }
        },
      }
    },
  }
}

export const configureAuth = () => {
  const config = {
    Auth: {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    },
  }

  Amplify.configure(config)
}

export const getAuth = (): any => {
  return (window as any)?.Cypress ? MockAuth : _Auth
}
