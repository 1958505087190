import { SUPPORTED_WALLETS } from 'constants/index'

import React, { FC } from 'react'
import { useWeb3ReactWithDAG } from 'hooks/useWeb3React'
import { injected } from 'connectors/index'
import StatusIcon from 'components/StatusIcon'
import Button from 'components/common/Button'
import Link from 'components/common/Link'
import { shortenAddress, getEtherscanLink, getBlockExplorerLink } from 'utils'
import { ReactComponent as OpenIcon } from 'assets/images/svgs/open.svg'
import { ReactComponent as CopyIcon } from 'assets/images/svgs/copy.svg'

import Copy from './Copy'
import styles from './index.module.scss'

interface IAccountView {
  openOptions: () => void
}

const AccountView: FC<IAccountView> = ({ openOptions }) => {
  // const { chainId, account, connector } = useActiveWeb3React()
  const { account, dagAccount, connector, chainId } = useWeb3ReactWithDAG()

  console.log('AccountView -> typeof account:', typeof(account));

  function formatConnectorName() {
    const { ethereum } = window
    console.log('AccountView -> ethereum:', ethereum);

    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        (k) =>
          SUPPORTED_WALLETS[k].connector === connector &&
          (connector !== injected || isMetaMask === (k === 'METAMASK'))
      )
      .map((k) => SUPPORTED_WALLETS[k].name)[0]
    return <span>Connected with {name}</span>
  }

  return (
    <>
      <div className={styles.wrapper}>
        <section className={styles.heading}>
          {formatConnectorName()}
          <Button
            theme={'secondary'}
            variant={styles.change}
            onClick={() => {
              openOptions()
            }}
          >
            Change
          </Button>
        </section>
        {dagAccount && (
          <>
            <section className={styles.content}>
              <StatusIcon connector={connector} />
              <span>shortenAddress</span>
            </section>
            <section className={styles.action}>
              {<Copy toCopy={dagAccount}>Copy address</Copy>}
              <Link
                href={getBlockExplorerLink(dagAccount)}
                variant={styles.link}
              >
                <OpenIcon />
                Block Explorer
              </Link>
            </section>
          </>
        )}
        {account && (
          <>
            <section className={styles.content}>
              <StatusIcon connector={connector} />
              <span>{shortenAddress(account)}</span>
            </section>
            <section className={styles.action}>
              <CopyIcon />
              {<Copy toCopy={account}>Copy address</Copy>}
              {chainId && (
                <Link
                  href={getEtherscanLink(chainId, account, 'address')}
                  variant={styles.link}
                >
                  <OpenIcon />
                  Etherscan
                </Link>
              )}
            </section>
          </>
        )}
      </div>
    </>
  )
}

export default AccountView
