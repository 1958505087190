import { useContext } from 'react'
import { UserContext } from 'providers/user'

interface UserHookI {
  user: Record<string, any>
  userDidLoad: boolean
  dbUser: Record<string, any>
  dbUserDidLoad: boolean
  signIn: (username: string, password: string) => Promise<Record<string, any>>
  signOut: () => Promise<Record<string, any>>
  signUp: (email: string, password: string) => Promise<Record<string, any>>
  reloadUser: () => Promise<Record<string, any>>
  verifyUserAttribute: (
    user: Record<string, any>,
    attr: string
  ) => Promise<Record<string, any>>
  confirmSignUp: (email: string, code: string) => Promise<Record<string, any>>
  verifyCurrentUserAttributeSubmit: (
    attr: string,
    code: string
  ) => Promise<Record<string, any>>
  updateUserAttributes: (
    user: Record<string, any>,
    attributes: Record<string, any>
  ) => Promise<Record<string, any>>
  changePassword: (
    user: Record<string, any>,
    passwordOld: string,
    passwordNew: string
  ) => Promise<Record<string, any>>
  forgotPassword: (email: string) => Promise<Record<string, any>>
  forgotPasswordSubmit: (
    email: string,
    code: string,
    password: string
  ) => Promise<Record<string, any>>
  setCognitoUser: (user: any) => void
}

const useUser = (): UserHookI => {
  const context = useContext(UserContext) as unknown

  if (context === undefined) {
    throw new Error(
      '`useUser` hook must be used within a `UserProvider` component'
    )
  }
  return context as UserHookI
}

export default useUser
