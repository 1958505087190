import React, { FC } from 'react'
import { AbstractConnector } from '@web3-react/abstract-connector'
import {
  fortmatic,
  injected,
  portis,
  walletconnect,
  walletlink,
} from 'connectors/index'
import CoinbaseWalletIcon from 'assets/images/wallets/coinbaseWalletIcon.svg'
import FortmaticIcon from 'assets/images/wallets/fortmaticIcon.png'
import PortisIcon from 'assets/images/wallets/portisIcon.png'
import WalletConnectIcon from 'assets/images/wallets/walletConnectIcon.svg'

import IdentIcon from '../common/IdentIcon'

import styles from './index.module.scss'

interface IStatusIcon {
  connector?: AbstractConnector
}

const StatusIcon: FC<IStatusIcon> = ({ connector }) => {
  if (connector === injected) {
    return <IdentIcon />
  }
  if (connector === walletconnect) {
    return (
      <i className={styles.wrapper}>
        <img src={WalletConnectIcon} alt={''} />
      </i>
    )
  }
  if (connector === walletlink) {
    return (
      <i className={styles.wrapper}>
        <img src={CoinbaseWalletIcon} alt={''} />
      </i>
    )
  }
  if (connector === fortmatic) {
    return (
      <i className={styles.wrapper}>
        <img src={FortmaticIcon} alt={''} />
      </i>
    )
  }
  if (connector === portis) {
    return (
      <i className={styles.wrapper}>
        <img src={PortisIcon} alt={''} />
      </i>
    )
  }
  return null
}

export default StatusIcon
