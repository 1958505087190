import React, { FC, useReducer, Suspense } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import ProtectedRoute from 'components/ProtectedRoute';
import Footer from 'components/Footer';
import Loader from 'components/common/Loader';
import Header from 'components/Header';
import WalletModalBackground from 'components/WalletModalBackground';

import SideBar, { MobileSideBar } from 'components/SideBar';
import routes from './routes';
import styles from './App.module.scss';

console.log('APP');

const App: FC = () => {
  const [openMenu, toggleOpen] = useReducer((s) => !s, false);

  const location = useLocation();

  const currentRoute = routes.find((route) => {
    return route.to == location.pathname;
  });
  console.log(currentRoute);

  function HeaderOutput() {
    if (currentRoute?.navMenuVisible) {
      return (
        <>
          <MobileSideBar openMenu={openMenu} handleClick={() => toggleOpen()} />
          <Header open={openMenu} onButtonClick={() => toggleOpen()} />
          <WalletModalBackground />
        </>
      );
    } else {
      return <></>;
    }
  }

  function SidebarOutput() {
    if (currentRoute?.navMenuVisible) {
      return <SideBar />;
    } else {
      return <></>;
    }
  }

  function FooterOutput() {
    if (currentRoute?.navMenuVisible) {
      return <Footer />;
    } else {
      return <></>;
    }
  }

  const bodyStyles = currentRoute?.navMenuVisible
    ? styles.bodyWrapper
    : styles.blankWrapper;

  return (
    <div className={bodyStyles}>
      <HeaderOutput />
      <div className={styles.body}>
        <SidebarOutput />
        <div className={styles.page}>
          <Suspense fallback={<Loader />}>
            <Switch>
              {routes
                .filter((route) => route.to !== undefined)
                .map((route) => (
                  <Route
                    exact={route.exact}
                    strict={route.exact}
                    path={route.to}
                    key={route.name}
                  >
                    {route.isProtected ? (
                      <ProtectedRoute component={route.component} />
                    ) : (
                      <route.component />
                    )}
                  </Route>
                ))}
              <Redirect to="/dashboard" />
            </Switch>
          </Suspense>
        </div>
      </div>
      <FooterOutput />
    </div>
  );
};

export default App;
