import React, { FC, useState, useMemo, useEffect } from 'react'
// import { Link, NavLink, useLocation } from 'react-router-dom'
import { NavLink, useLocation } from 'react-router-dom'
import cls from 'classnames'
import { useWalletModalToggle } from 'state/application/hooks'
import { ReactComponent as ArrowUp } from 'assets/images/svgs/arrow-up.svg'
import { ReactComponent as ArrowDown } from 'assets/images/svgs/arrow-down.svg'
import { ReactComponent as Close } from 'assets/images/svgs/close.svg'

import { useWeb3ReactWithDAG } from 'hooks/useWeb3React'
import Button from '../common/Button'
import styles from './index.module.scss'
import { menuItems } from './data'

interface ISideBarProps {
  handleClick?: () => void
  menuList: typeof menuItems
  mobile?: boolean
}
interface IMobileSideBarProps {
  openMenu: boolean
  handleClick: () => void
}
interface IToggle {
  [x: string]: boolean
}

const SideBar: FC<ISideBarProps> = ({ menuList, mobile, handleClick }) => {
  const { pathname } = useLocation()
  const toggleWalletModal = useWalletModalToggle()

  useEffect(() => {
    if (pathname !== '/') {
      const index = menuList.findIndex(
        (item) => pathname.split('/')[1] === item.link.slice(1)
      )
      if (index >= 0) {
        const id = menuList[index].id
        setCollapsedToggle((prevState) => ({ ...prevState, [id]: true }))
      }
    }
  }, [])

  const initialState = useMemo(() => {
    return menuList.reduce((acc: IToggle, next) => {
      if (next.submenu && next.submenu.length > 0) {
        acc[next.id] = false
      }
      return acc
    }, {})
  }, [menuList])

  const handleCollapsedToggle = (id: string) => {
    setCollapsedToggle((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }
  const handleMenuSelect = () => {
    setCollapsedToggle(initialState)
  }
  // const handleSubMenuSelect = (link: string, id: string) => {
  //   setCollapsedToggle({ ...initialState, [id]: true })
  // }
  const handleMenuToggle = (id: string, submenu: any) => {
    submenu && submenu.length > 0
      ? handleCollapsedToggle(id)
      : handleMenuSelect()
  }

  const [toggleCollapsed, setCollapsedToggle] = useState<IToggle>(initialState)
  const toggle = () => {
    handleClick && handleClick()
    toggleWalletModal()
  }

  const { account, dagAccount } = useWeb3ReactWithDAG()
  return (
    <div className={styles.menuContainer}>
      {menuList.map(({ Icon, id, name, submenu, className, link }) => (
        <div
          onClick={() => {
            handleMenuToggle(id, submenu)
          }}
          key={id}
          className={cls(styles.menu, {
            [styles.hasSubmenu]: submenu && submenu.length,
          })}
        >
          {(!submenu || submenu.length === 0) && link.startsWith("http") ? (
            <a href={link} className={cls(styles.menuItem, {
              [styles.active]: pathname === link,
            })}>
              <div className={cls(styles.icon, styles[className])}>
                <Icon />
              </div>
              <div className={styles.text}>{name}</div>
            </a>
          ) : (
            <NavLink
              to={link}
              onClick={handleClick}
              className={cls(styles.menuItem, {
                [styles.active]: pathname === link,
              })}
            >
              <div className={cls(styles.icon, styles[className])}>
                <Icon />
              </div>
              <div className={styles.text}>{name}</div>
            </NavLink>
          )}
          {submenu && submenu.length >0 && (
            <div
              className={cls(styles.menuItem, {
                [styles.active]:
                  submenu && submenu.length
                    ? toggleCollapsed[id]
                    : pathname.includes(link),
              })}
            >
              <div className={cls(styles.icon, styles[className])}>
                <Icon />
              </div>
              <div className={styles.text}>{name}</div>

              <div className={styles.arrowIcon}>
                {toggleCollapsed[id] ? <ArrowUp /> : <ArrowDown />}
              </div>
            </div>
          )}

        </div>
      ))}
      {mobile && (
        <div className={styles.connectbtn}>
          {account || dagAccount ? (
            <Button theme={'secondary'} onClick={toggle}>
              Wallet
            </Button>
          ) : (
            <Button theme={'secondary'} onClick={toggle}>
              Connect Wallet
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export const DesktopSideBar = () => {
  return (
    <div className={styles.sidebar}>
      <SideBar menuList={menuItems} />
    </div>
  )
}

export const MobileSideBar: FC<IMobileSideBarProps> = ({
  openMenu,
  handleClick,
}) => {
  return (
    <div
      className={cls(styles.mobileContainer, {
        [styles.modalVisible]: openMenu,
      })}
    >
      <div className={styles.mobileSidebar}>
        <div className={styles.closebtn}>
          <Button theme={'transparent'} onClick={handleClick}>
            <Close />
          </Button>
        </div>
        <SideBar handleClick={handleClick} menuList={menuItems} mobile={true} />
      </div>
      <div className={styles.overlay} onClick={handleClick}></div>
    </div>
  )
}
export default DesktopSideBar
