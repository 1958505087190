import React, { FC, ReactNode, SVGProps } from 'react'
import cls from 'classnames'
import Portal from '@reach/portal'
import { ReactComponent as CloseIcon } from 'assets/images/svgs/close.svg'

import Button from '../Button'

import styles from './index.module.scss'

export enum PanelVariant {
  CONTENT,
  BOX,
  WIDGET,
}

interface IPanel {
  children: ReactNode
  title: string | ReactNode
  Icon?: FC<SVGProps<SVGSVGElement>>
  backmask?: string
  variant?: string
  container?: string
  onClose?: () => void
  onIconClick?: () => void
  open?: boolean
  SubPanel?: ReactNode
  type?: PanelVariant
  isInline?: boolean
  middle?: ReactNode
  tag?: ReactNode
  dataCy?: string
}

const Panel: FC<IPanel> = ({
  title,
  children,
  backmask,
  variant,
  container,
  onClose,
  open = true,
  SubPanel,
  type = PanelVariant.BOX,
  isInline = false,
  middle,
  tag,
  dataCy,
}) => {
  const wrapperClass = cls(
    styles.wrapper,
    backmask,
    { [styles.hide]: !open },
    { [styles.inline]: isInline },
    { [styles.widgetWrap]: type === PanelVariant.WIDGET }
  )
  const panelClass = cls(
    styles.panel,
    {
      [styles.content]: type === PanelVariant.CONTENT,
      [styles.widget]: type === PanelVariant.WIDGET,
    },
    variant
  )
  const titleClass = cls(styles.title, { [styles.showClose]: onClose })
  const bodyClass = cls(styles.body, { [container || '']: container })
  const headingClass = cls(styles.heading, { [styles.hideBorder]: !title })

  const Wrapper = isInline ? 'div' : Portal

  return (
    <Wrapper>
      <div className={wrapperClass} data-cy={dataCy}>
        <div className={styles.inner}>
          <div className={panelClass}>
            <section className={headingClass}>
              <span
                className={titleClass}
                data-cy={type === PanelVariant.CONTENT && 'panel-title'}
              >
                {title}
              </span>
              {onClose && (
                <Button variant={styles.close} onClick={onClose}>
                  <CloseIcon />
                </Button>
              )}
              {type === PanelVariant.WIDGET && tag}
            </section>
            {type === PanelVariant.WIDGET && middle && (
              <section className={styles.middle}>{middle}</section>
            )}
            <section className={bodyClass}>{children}</section>
          </div>
          {SubPanel && <div className={styles.subpanel}>{SubPanel}</div>}
        </div>
      </div>
    </Wrapper>
  )
}

export default Panel
