import axios from 'axios'

import { getAuth } from './auth'

export class APIError extends Error {
  public errorCode: 400 | 404 | 401 | 500

  constructor(message: string, errorCode: 400 | 404 | 401 | 500) {
    super(message)
    this.errorCode = errorCode
  }
}

export interface apiRequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  endpoint: string
  body?: Record<string, any>
  isAuthenticated?: boolean
}

export const apiRequest = async ({
  method,
  endpoint,
  body,
  isAuthenticated = false,
}: apiRequestOptions): Promise<{ data: any; meta?: any }> => {
  try {
    const headers: Record<string, string> = {}

    if (isAuthenticated) {
      let user
      try {
        user = await getAuth().currentAuthenticatedUser()
      } catch (err) {
        // NOOP
      }

      if (!user) {
        throw new APIError('User is not logged in', 401)
      }

      const accessToken = user
        .getSignInUserSession()
        .getAccessToken()
        .getJwtToken()

      headers.Authorization = `Bearer ${accessToken}`
    }

    const { status, statusText, data } = await axios({
      method,
      url: `${process.env.REACT_APP_API_BASE_URL}${endpoint}`,
      headers,
      data: body,
    })

    if (status < 200 || status >= 400) {
      throw new APIError(statusText, status as any)
    }

    return {
      data: data.data,
      meta: data.meta,
    }
  } catch (err: any) {
    if (err.response?.data?.message) {
      throw new APIError(
        err.response?.data?.message,
        err.response.status as any
      )
    }

    throw err
  }
}

export default apiRequest
