import { lazy } from 'react';
import config from '../config';

import KYC from './KYC';
const Dashboard = lazy(() => import('./Dashboard'));
const JamStakingPool = lazy(() => import('./JamStakingPool'));
const MyAccount = lazy(() => import('./MyAccount'));
const Proposal = lazy(() => import('./Proposals'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const ResetPasswordConfirm = lazy(() => import('./ResetPasswordConfirm'));
const SignIn = lazy(() => import('./SignIn'));
const SignOut = lazy(() => import('./SignOut'));
const SignUp = lazy(() => import('./SignUp'));
const SignUpConfirm = lazy(() => import('./SignUpConfirm'));
const Privacy = lazy(() => import('./Privacy'));
const TermsOfService = lazy(() => import('./TermsOfService'));
const AppPrivacy = lazy(() => import('./AppPrivacy'));
const AppTerms = lazy(() => import('./AppTerms'));
const GeojamPrivacy = lazy(() => import('./GeojamPrivacy'));
const GeojamTerms = lazy(() => import('./GeojamTerms'));
const AI = lazy(() => import('./AI'));
const Campaign = lazy(() => import('./Campaign'));
const Trends = lazy(() => import('./Trends'));
const TrendsWithdraw = lazy(() => import('./Trends/Withdraw'));
const Metrics = lazy(() => import('./Metrics'));

export interface RouteConfig {
  to?: string;
  name: string;
  navMenuVisible?: boolean;
  strict?: boolean;
  exact?: boolean;
  isProtected?: boolean;
  component: any;
  icon?: any;
  parent?: string;
}

const routes: Array<RouteConfig> = [
  {
    to: '/sign-in',
    name: 'Sign In',
    navMenuVisible: true,
    strict: true,
    component: SignIn,
  },
  {
    to: '/sign-out',
    name: 'Sign Out',
    navMenuVisible: true,
    strict: true,
    component: SignOut,
  },
  {
    to: '/sign-up',
    name: 'Sign Up',
    navMenuVisible: true,
    strict: true,
    component: SignUp,
  },
  {
    to: '/my-account',
    name: 'My Account',
    navMenuVisible: false,
    strict: true,
    isProtected: true,
    component: MyAccount,
  },
  {
    to: '/reset-password',
    name: 'Reset Password',
    navMenuVisible: true,
    strict: true,
    component: ResetPassword,
  },
  {
    to: '/reset-password-confirm',
    name: 'Reset Password Confirm',
    navMenuVisible: true,
    strict: true,
    component: ResetPasswordConfirm,
  },
  {
    to: '/verify-account',
    name: 'Verify Account',
    navMenuVisible: true,
    strict: true,
    component: SignUpConfirm,
  },
  {
    to: '/kyc',
    name: 'KYC',
    navMenuVisible: false,
    strict: true,
    isProtected: true,
    component: KYC,
  },
  {
    to: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
    strict: true,
    navMenuVisible: true,
  },
  {
    to: '/proposal',
    name: 'Proposal',
    component: Proposal,
    exact: true,
    strict: true,
    navMenuVisible: true,
  },
  {
    to: config.routes.jamStaking,
    name: '$JAM Staking Pools',
    component: JamStakingPool,
    exact: true,
    strict: true,
    navMenuVisible: true,
  },
  {
    to: config.routes.privacy,
    name: 'Privacy Policy',
    component: Privacy,
    exact: true,
    strict: true,
    navMenuVisible: true,
  },
  {
    to: config.routes.termsOfService,
    name: 'Terms of Service',
    component: TermsOfService,
    exact: true,
    strict: true,
    navMenuVisible: true,
  },
  {
    to: config.routes.appPrivacy,
    name: 'Privacy Policy',
    component: AppPrivacy,
    exact: true,
    strict: true,
    navMenuVisible: false,
  },
  {
    to: config.routes.appTerms,
    name: 'Terms of Service',
    component: AppTerms,
    exact: true,
    strict: true,
    navMenuVisible: false,
  },
  {
    to: config.routes.geojamPrivacy,
    name: 'Privacy Policy',
    component: GeojamPrivacy,
    exact: true,
    strict: true,
    navMenuVisible: false,
  },
  {
    to: config.routes.geojamTerms,
    name: 'Terms of Service',
    component: GeojamTerms,
    exact: true,
    strict: true,
    navMenuVisible: false,
  },
  {
    to: '/ai',
    name: 'AI',
    navMenuVisible: false,
    strict: false,
    isProtected: false,
    component: AI,
  },
  {
    to: '/campaign',
    name: 'Campaign',
    navMenuVisible: false,
    strict: false,
    isProtected: false,
    component: Campaign,
  },
  {
    to: '/trends',
    name: 'Trends',
    navMenuVisible: false,
    strict: false,
    isProtected: false,
    component: Trends,
  },
  {
    to: '/trends-withdraw',
    name: 'TrendsWithdraw',
    navMenuVisible: false,
    strict: false,
    isProtected: false,
    component: TrendsWithdraw,
  },
  {
    to: '/metrics',
    name: 'Metrics',
    navMenuVisible: false,
    strict: false,
    isProtected: false,
    component: Metrics,
  },
];

export default routes;
