const config = {
  routes: {
    privacy: '/privacy-policy',
    termsOfService: '/terms-of-service',
    contactUs: '/contact',
    dashboard: '/dashboard',
    staking: '/staking',
    jamStaking: '/staking/jam',
    creatorAccess: '/staking/creator',
    proposals: '/proposal',
    signin: '/sign-in',
    signup: '/sign-up',
    signout: '/sign-out',
    kyc: '/kyc',
    myAccount: '/my-account',
    jamshop: 'https://geojam.com/jam-shop/',
    appPrivacy: '/app-privacy',
    appTerms: '/app-terms',
    geojamPrivacy: '/geojam-privacy',
    geojamTerms: '/geojam-terms',
    ai: '/ai',
  },
  constants: {
    faqViewLimit: 4,
    whitepaperLink:
      'https://geojam.docsend.com/view/d5cnkf7h4jysyfpd/d/ffsbtvuc3u8x3pcp',
    whitepaperFileName: 'JAM-Whitepaper-Geojam-Official.pdf',
  },
};
export default config;
