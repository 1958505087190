import { AbstractConnector } from '@web3-react/abstract-connector'
import { ChainId, Token, WETH, JSBI, Percent } from '@uniswap/sdk'
import {
  // fortmatic,
  injected,
  // stargazer,
  // portis,
  // walletconnect,
  // walletlink,
} from 'connectors/index'

export interface WalletInfo {
  connector?: AbstractConnector
  description: string
  href: string | null
  iconName: string | null
  mobile?: boolean
  mobileOnly?: true
  name: string
  primary?: true
  getSubHeader?: () => string | null // Dynamically generate subheader if needed
  showLink?: () => boolean // Dyanically decide to have opt link or trigger ext option
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  // STARGAZER: {
  //   connector: stargazer,
  //   name: 'Stargazer',
  //   iconName: 'stargazer.png',
  //   description: 'Multi-chain wallet for DAG and ETH.',
  //   href: 'https://chrome.google.com/webstore/detail/stargazer-wallet/pgiaagfkgcbnmiiolekcfmljdagdhlcm',
  //   mobile: false,
  //   getSubHeader: () => {
  //     // Stargazer installed but needs update
  //     if (
  //       window.hasOwnProperty('stargazer') &&
  //       !(window as any).stargazer.hasOwnProperty('getProvider')
  //     ) {
  //       return 'Update to v3.1.2 or later'
  //     }
  //
  //     return null
  //   },
  //   showLink: () => {
  //     // Not installed
  //     return !window.hasOwnProperty('stargazer')
  //   },
  // },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: 'https://metamask.io/',
    mobile: false,
    showLink: () => {
      return (
        !window.hasOwnProperty('ethereum') ||
        !(window as any).ethereum.isMetaMask
      )
    },
  },
  // WALLET_CONNECT: {
  //   connector: walletconnect,
  //   name: 'WalletConnect',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   mobile: true,
  // },
  // COINBASE: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   mobile: true,
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Sign in using Fortmatic hosted wallet',
  //   href: null,
  //   mobile: true,
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Sign in using Portis hosted wallet',
  //   href: null,
  //   mobile: false,
  // },
}

export const NetworkContextName = 'NETWORK'

export const EthereumWalletsLink = 'https://ethereum.org/wallets/'

type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const DAI = new Token(
  ChainId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
)
export const USDT = new Token(
  ChainId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
)
export const COMP = new Token(
  ChainId.MAINNET,
  '0xc00e94Cb662C3520282E6f5717214004A7f26888',
  18,
  'COMP',
  'Compound'
)
export const MKR = new Token(
  ChainId.MAINNET,
  '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
  18,
  'MKR',
  'Maker'
)
export const AMPL = new Token(
  ChainId.MAINNET,
  '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
  9,
  'AMPL',
  'Ampleforth'
)
export const WBTC = new Token(
  ChainId.MAINNET,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  18,
  'WBTC',
  'Wrapped BTC'
)

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [
    ...WETH_ONLY[ChainId.MAINNET],
    DAI,
    USDC,
    USDT,
    COMP,
    MKR,
  ],
}

const UNI_ADDRESS = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'
export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    UNI_ADDRESS,
    18,
    'UNI',
    'Uniswap'
  ),
  [ChainId.RINKEBY]: new Token(
    ChainId.RINKEBY,
    UNI_ADDRESS,
    18,
    'UNI',
    'Uniswap'
  ),
  [ChainId.ROPSTEN]: new Token(
    ChainId.ROPSTEN,
    UNI_ADDRESS,
    18,
    'UNI',
    'Uniswap'
  ),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: {
  [chainId in ChainId]?: { [tokenAddress: string]: Token[] }
} = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]],
  },
}

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(
  JSBI.BigInt(75),
  JSBI.BigInt(10000)
)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(
  JSBI.BigInt(100),
  BIPS_BASE
) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(
  JSBI.BigInt(300),
  BIPS_BASE
) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(
  JSBI.BigInt(500),
  BIPS_BASE
) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
  JSBI.BigInt(1000),
  BIPS_BASE
) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(
  JSBI.BigInt(1500),
  BIPS_BASE
) // 15%

export const ROUTER_ADDRESS = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'
export const STAKING_POOL = process.env.REACT_APP_STAKING_POOL_ADDRESS || ''
export const LATTICE_TOKEN = process.env.REACT_APP_STAKING_TOKEN_ADDRESS || ''
export const LTX_PRECISION_UNITS = 8
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'
