import { AbstractConnectorArguments, ConnectorUpdate } from '@web3-react/types'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { IRequestParams, ConnectorEvents } from './types'
import { NoStargazerProviderError, UserRejectedRequestError } from './errors'
import { info, warn } from './lib'

export class StargazerConnector extends AbstractConnector {
  private ethereumProvider
  private constellationProvider
  private request: (params: IRequestParams) => Promise<any>
  public constellationAccounts: string[]
  public ethereumAccounts: string[]

  constructor(kwargs: AbstractConnectorArguments) {
    super(kwargs)

    this.handleNetworkChanged = this.handleNetworkChanged.bind(this)
    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleConstellationAccountsChanged =
      this.handleConstellationAccountsChanged.bind(this)
    this.handleConstellationClose = this.handleConstellationClose.bind(this)
    this.emitConstellationUpdate = this.emitConstellationUpdate.bind(this)

    const stargazer = (window as any).stargazer

    if (stargazer && stargazer.getProvider) {
      this.ethereumProvider = (window as any).stargazer.getProvider('ethereum')
      this.constellationProvider = (window as any).stargazer.getProvider(
        'constellation'
      )
    } else {
      // Basic support for older versions of Stargazer
      this.ethereumProvider = stargazer
    }

    this.constellationAccounts = []
    this.ethereumAccounts = []

    // Wrap request so we can log calls and parse results
    this.request = async (params: IRequestParams): Promise<any> => {
      let response
      if (params.method && params.method.startsWith('dag_')) {
        response = await this.constellationProvider.request(params)
      } else {
        response = await this.ethereumProvider.request(params)
      }

      return response.hasOwnProperty('result') ? response.result : response
    }
  }

  private handleChainChanged(chainId: string | number): void {
    info("Handling 'chainChanged' event with payload", chainId)
    this.emitUpdate({ chainId, provider: this.ethereumProvider })
  }

  private handleAccountsChanged(accounts: string[]): void {
    info("Handling 'accountsChanged' event with payload", accounts)

    this.ethereumAccounts = accounts

    if (
      this.ethereumAccounts.length === 0 &&
      this.constellationAccounts.length === 0
    ) {
      this.emitDeactivate()
    } else {
      this.emitUpdate({
        account: this.ethereumAccounts[0],
        provider: this.ethereumProvider,
      })
    }
  }

  private handleClose(code: number, reason: string): void {
    info("Handling 'close' event with payload", code, reason)

    this.emitDeactivate()
  }

  private handleNetworkChanged(networkId: string | number): void {
    info("Handling 'networkChanged' event with payload", networkId)

    this.emitUpdate({ chainId: networkId, provider: this.ethereumProvider })
  }

  private handleConstellationAccountsChanged(accounts: string[]): void {
    info("Handling 'accountsChanged' event with payload", accounts)

    this.constellationAccounts = accounts

    if (
      this.ethereumAccounts.length === 0 &&
      this.constellationAccounts.length === 0
    ) {
      this.emitDeactivate()
    } else {
      this.emitConstellationUpdate({
        account: accounts[0],
      })
    }
  }

  private handleConstellationClose(code: number, reason: string): void {
    info("Handling 'close' event with payload", code, reason)

    this.emitDeactivate()
  }

  public async activate(): Promise<ConnectorUpdate> {
    if (!this.ethereumProvider || !this.constellationProvider) {
      throw new NoStargazerProviderError()
    }

    if (this.ethereumProvider.on) {
      this.ethereumProvider.on('chainChanged', this.handleChainChanged)
      this.ethereumProvider.on('accountsChanged', this.handleAccountsChanged)
      this.ethereumProvider.on('close', this.handleClose)
      this.ethereumProvider.on('networkChanged', this.handleNetworkChanged)

      this.constellationProvider.on(
        'accountsChanged',
        this.handleConstellationAccountsChanged
      )
      this.constellationProvider.on('close', this.handleConstellationClose)
    }

    // try to activate + get account via eth_requestAccounts
    let account
    try {
      const response = await this.request({
        method: 'eth_requestAccounts',
      })

      account = response[0]

      const dagAccounts = await this.request({
        method: 'dag_accounts',
      })

      this.handleConstellationAccountsChanged(dagAccounts)
    } catch (error) {
      if ((error as any).code === 4001) {
        throw new UserRejectedRequestError()
      }

      warn('eth_requestAccounts was unsuccessful')
    }

    return { provider: this.ethereumProvider, ...(account ? { account } : {}) }
  }

  public async getProvider(): Promise<any> {
    return this.ethereumProvider
  }

  public async getProviderType(type: 'ethereum' | 'constellation') {
    return type === 'constellation'
      ? this.constellationProvider
      : this.ethereumProvider
  }

  public async getChainId(): Promise<number | string> {
    if (!this.ethereumProvider) {
      throw new NoStargazerProviderError()
    }

    let chainId
    try {
      chainId = await this.request({ method: 'eth_chainId' })
    } catch {
      warn('eth_chainId was unsuccessful, falling back to net_version')
    }

    return chainId
  }

  public async getAccount(): Promise<null | string> {
    if (!this.ethereumProvider) {
      throw new NoStargazerProviderError()
    }

    try {
      const account = (
        (await this.request({
          method: 'eth_accounts',
        })) as Array<string>
      )[0]
      return account
    } catch {
      warn('eth_accounts was unsuccessful')
      return null
    }
  }

  public deactivate() {
    if (this.ethereumProvider && this.ethereumProvider.removeListener) {
      this.ethereumProvider.removeListener(
        'chainChanged',
        this.handleChainChanged
      )
      this.ethereumProvider.removeListener(
        'accountsChanged',
        this.handleAccountsChanged
      )
      this.ethereumProvider.removeListener('close', this.handleClose)
      this.ethereumProvider.removeListener(
        'networkChanged',
        this.handleNetworkChanged
      )
    }
  }

  public async isAuthorized(): Promise<boolean> {
    if (!this.ethereumProvider) {
      return false
    }

    try {
      return (await this.request({ method: 'eth_accounts' })).length > 0
    } catch {
      return false
    }
  }

  private emitConstellationUpdate(update: { account: string }): void {
    this.emit(ConnectorEvents.ConstellationUpdate, update)
  }
}
