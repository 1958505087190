import { FC, SVGProps } from 'react'
// import { ReactComponent as SpinnerIcon } from 'assets/images/svgs/spinner.svg'
// import cls from 'classnames'

// import styles from './index.module.scss'

interface ILoader {
  size?: number
  Icon?: FC<SVGProps<SVGSVGElement>>
  variant?: string
}

const Loader: FC<ILoader> = () => {
  // Return nothing for now until we can get a better component built
  return null
}

export default Loader
