import React, { FC, createContext, useState, useEffect, useMemo } from 'react'

import { apiRequest } from '../utils/apiRequest'
import { getAuth, configureAuth } from '../utils/auth'

export const UserContext = createContext(null)

export const UserProvider: FC = ({ children }) => {
  const [cognitoUser, setCognitoUser] = useState(null)
  const [dbUser, setDBUser] = useState(null)
  const [cognitoUserDidLoad, setCognitoUserDidLoad] = useState(false)
  const [dbUserDidLoad, setDBUserDidLoad] = useState(false)

  const Auth = getAuth()

  useEffect(() => {
    configureAuth()

    // attempt to fetch already logged in user info
    Auth.currentAuthenticatedUser()
      .then((user: any) => {
        setCognitoUser(user)
        setCognitoUserDidLoad(true)

        return user
      })
      .catch(() => {
        setCognitoUser(null)
        setCognitoUserDidLoad(true)
      })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (cognitoUser === null) {
        setDBUser(null)
        return
      }

      return apiRequest({
        method: 'GET',
        endpoint: `/users/${(cognitoUser as any).getUsername()}`,
        isAuthenticated: true,
      })
        .then((response) => {
          setDBUser(response.data)
          setDBUserDidLoad(true)
        })
        .catch((err) => {
          console.log('Error', err)
          setDBUser(null)
          setDBUserDidLoad(true)
        })
    })()
  }, [cognitoUser])

  const signIn = async (
    usernameOrEmail: string,
    password: string
  ): Promise<Record<string, any>> => {
    return Auth.signIn(usernameOrEmail, password)
      .then((cognitoUser: any) => {
        setCognitoUser(cognitoUser)
        return cognitoUser
      })
      .catch((err: any) => {
        if (err.code === 'UserNotFoundException') {
          err.message = 'Invalid username or password'
        }

        throw err
      })
  }

  const signOut = () => {
    return Auth.signOut().then((data: any) => {
      setCognitoUser(null)
      return data
    })
  }

  const signUp = (email: string, password: string) => {
    return Auth.signUp(email, password)
  }

  const reloadUser = () => {
    return Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((user: any) => {
        // console.log('reloadUser', user)
        setCognitoUser(user)
        return user
      })
      .catch(() => setCognitoUser(null))
  }

  const verifyUserAttribute = (user: Record<string, any>, attr: string) => {
    return Auth.verifyUserAttribute(user, attr)
  }

  const confirmSignUp = (email: string, code: string) => {
    // return Auth.confirmSignUp(String(email), String(code))
    // return Auth.verifyCurrentUserAttributeSubmit(String(email), String(code))
    return Auth.verifyCurrentUserAttributeSubmit('email', String(code))
  }

  const verifyCurrentUserAttributeSubmit = (attr: string, code: string) => {
    return Auth.verifyCurrentUserAttributeSubmit(attr, String(code))
  }

  const updateUserAttributes = (
    user: Record<string, any>,
    attributes: Record<string, any>
  ) => {
    return Auth.updateUserAttributes(user, attributes)
  }

  const changePassword = (
    user: Record<string, any>,
    passwordOld: string,
    passwordNew: string
  ) => {
    return Auth.changePassword(user, passwordOld, passwordNew)
  }

  const forgotPassword = (email: string) => {
    return Auth.forgotPassword(email)
  }

  const forgotPasswordSubmit = (
    email: string,
    code: string,
    password: string
  ) => {
    return Auth.forgotPasswordSubmit(String(email), String(code), password)
  }

  const user = cognitoUser,
    userDidLoad = cognitoUserDidLoad

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` value has changed.
  const values = useMemo(
    () => ({
      user,
      dbUser,
      userDidLoad,
      dbUserDidLoad,
      signIn,
      signOut,
      signUp,
      reloadUser,
      verifyUserAttribute,
      confirmSignUp,
      verifyCurrentUserAttributeSubmit,
      updateUserAttributes,
      changePassword,
      forgotPassword,
      forgotPasswordSubmit,
      setCognitoUser,
    }),
    [user, dbUser, userDidLoad, dbUserDidLoad]
  )

  return (
    <UserContext.Provider value={values as any}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
