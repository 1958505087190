import React, { useState, useLayoutEffect } from 'react'
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import useUser from 'hooks/useUser'

interface Props {
  component: React.FC
}

const ProtectedRoute: React.FC<Props> = ({ component }) => {
  const [isAuthenticated, setLoggedIn] = useState(false)
  const [authChecked, setAuthChecked] = useState(false)
  const { user, userDidLoad } = useUser()

  const history = useHistory()

  useLayoutEffect(() => {
    let mounted = true

    const setAuth = (val: boolean) => {
      if (!mounted || !userDidLoad) return

      setLoggedIn(val)
      setAuthChecked(true)
    }

    if (user) {
      if (!user.attributes.email_verified) {
        return history.push(`/verify-account?email=${user.attributes.email}`)
      }

      setAuth(!!user)
    } else {
      setAuth(false)
    }

    return () => {
      mounted = false
    }
  })

  const location = useLocation()

  if (!authChecked) return null

  return (
    <Route
      render={() =>
        isAuthenticated ? (
          React.createElement(component)
        ) : (
          <Redirect to={`/sign-in?redirect=${location.pathname}`} />
        )
      }
    />
  )
}

export default ProtectedRoute
