import React, { FC, ReactNode } from 'react'
import Button from 'components/common/Button'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { ReactComponent as CopyIcon } from 'assets/images/svgs/copy.svg'

import styles from './index.module.scss'

interface ICopyAddress {
  toCopy: string
  children: ReactNode
}

const CopyAddress: FC<ICopyAddress> = ({ toCopy, children }) => {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <Button variant={styles.copy} onClick={() => setCopied(toCopy)}>
      <CopyIcon />
      {isCopied ? <span>Copied</span> : <span>{children}</span>}
    </Button>
  )
}

export default CopyAddress
