import { createSlice, PayloadAction, nanoid } from '@reduxjs/toolkit'

import { ApplicationModal, PopupList } from './types'

export interface IApplicationState {
  readonly openModal: ApplicationModal | null
  readonly popupList: PopupList
  readonly blockNumber: { readonly [chainId: number]: number }
}

const initialState: IApplicationState = {
  openModal: null,
  popupList: [],
  blockNumber: {},
}

const ApplicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setOpenModal(state, action: PayloadAction<ApplicationModal | null>) {
      state.openModal = action.payload
    },
    addPopup(state, { payload: { content, key, removeAfterMs = 15000 } }) {
      state.popupList = (
        key
          ? state.popupList.filter((popup) => popup.key !== key)
          : state.popupList
      ).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ])
    },
    removePopup(state, { payload: { key } }) {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false
        }
      })
    },
    updateBlockNumber(
      state,
      action: PayloadAction<{ chainId: number; blockNumber: number }>
    ) {
      const { chainId, blockNumber } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(
          blockNumber,
          state.blockNumber[chainId]
        )
      }
    },
  },
})

export const { setOpenModal, addPopup, removePopup, updateBlockNumber } =
  ApplicationSlice.actions

export default ApplicationSlice.reducer
