import React, { useState, useEffect } from 'react'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import useUser from 'hooks/useUser'
import Panel, { PanelVariant } from 'components/common/Panel'
import Button from 'components/common/Button'
import { ReactComponent as GreenCheckIcon } from 'assets/images/svgs/success.svg'
import { ReactComponent as PendingIcon } from 'assets/images/svgs/pending-dots.svg'

import styles from './index.module.scss'
import './index.css'

const RETRY_STATUSES = [
  'resubmission_requested',
  'expired',
  'abandoned',
  'declined',
]

const launchVeriff = (url: string) => {
  createVeriffFrame({
    url,
    onEvent: (msg) => {
      console.log('msg: ', msg)
      //TODO: Update cached user + page status after closing or completing
      switch (msg) {
        case MESSAGES.CANCELED:
          break
      }
    },
  })
}
const renderKycRoot = (): JSX.Element | null => {
  return <div id="veriff-root" />
}
const renderStatus = (
  dbUser: Record<any, any>,
  setResetForm: (shouldSet: boolean) => any,
  setDidMountVeriff: (didMount: boolean) => any
): JSX.Element | null => {
  let text, icon, button, state
  if (dbUser?.kycStatus === 'approved' && dbUser?.kycCountryCode) {
    text = 'Approved'
    icon = <GreenCheckIcon />
  } else if (dbUser?.kycStatus === 'approved' && !dbUser?.kycCountryCode) {
    state = 'error'
    text =
      'Resubmission requested. Please repeat the ID verification process by clicking the button below.'
    button = true
  } else if (dbUser?.kycStatus === 'declined') {
    text = 'Declined'
    button = true
  } else if (RETRY_STATUSES.includes(dbUser?.kycStatus)) {
    state = 'error'
    text =
      'Resubmission requested. Please repeat the ID verification process by clicking the button below.'
    button = true
  } else {
    text = 'Pending'
    icon = <PendingIcon />
  }

  return (
    <div className={styles.kycStatusWrapper}>
      {state === 'error' ? (
        <>
          <div className={styles.statusTitleText}>Status </div>
          <p>{text}</p>
        </>
      ) : (
        <div>
          <p>Status: {text} </p>
        </div>
      )}
      <div className={styles.iconWrapper}>{icon}</div>
      {button && (
        <div className={styles.buttonWrapper}>
          <Button
            onClick={() => {
              setDidMountVeriff(false)
              setResetForm(true)
            }}
            theme="secondary"
          >
            Restart KYC
          </Button>
        </div>
      )}
    </div>
  )
}

const shouldRenderStatus = (id: string, status: string, resetForm: boolean) => {
  return !resetForm && (id || status)
}
const mountVeriff = (user: Record<string, any>) => {
  const veriff = (window as any).Veriff({
    host: 'https://stationapi.veriff.com',
    apiKey: process.env.REACT_APP_VERIFF_API_KEY,
    parentId: 'veriff-root',
    onSession: function (err: any, response: any) {
      launchVeriff(response.verification.url)
    },
  })

  veriff.setParams({
    vendorData: user?.attributes.email,
  })

  veriff.mount({
    formLabel: {
      givenName: 'Given Name',
      lastName: 'Last Name',
    },
    submitBtnText: 'Start Verification',
    loadingText: 'Please wait...',
  })
}

const KYC = (): JSX.Element | null => {
  const [didMountVeriff, setDidMountVeriff] = useState(false)
  const [resetForm, setResetForm] = useState(false)
  const [pollingCount, setPollingCount] = useState(0)
  const { user, dbUser, reloadUser } = useUser()

  const kycId = dbUser?.kycId
  const kycStatus = dbUser?.kycStatus

  // Refetches the user from Cognito every second
  useEffect(() => {
    let timeout: null | number = null

    ;(async () => {
      await reloadUser()

      console.log(1)
      console.log(user)
      // if (user === null || dbUser === null) {
      if (user === null) {
        // user was not present on render, wait for user or next poll
        timeout = window.setTimeout(() => {
          setPollingCount(pollingCount + 1)
        }, 1000)
        return
      }

      console.log(2)
      if (resetForm && !RETRY_STATUSES.includes(kycStatus)) {
        setResetForm(false)
      }

      console.log(3)
      if (!didMountVeriff && !shouldRenderStatus(kycId, kycStatus, resetForm)) {
        mountVeriff(user)

        setDidMountVeriff(true)
      }

      console.log(4)
      if (shouldRenderStatus(kycId, kycStatus, resetForm)) {
        // Veriff adds its form to the DOM outside of divs controlled by React so we have to remove it here
        document.querySelectorAll('.veriff-container')[0]?.remove()

        // Check again every 5 seconds for updates
        timeout = window.setTimeout(() => {
          setPollingCount(pollingCount + 1)
        }, 5000)
      } else {
        timeout = window.setTimeout(() => {
          setPollingCount(pollingCount + 1)
        }, 1000)
      }
    })()

    return () => {
      timeout !== null && window.clearTimeout(timeout)
      return
    }
  }, [resetForm, pollingCount])

  return (
    <div>
      <Panel title={'KYC'} type={PanelVariant.CONTENT} isInline={true}>
        {shouldRenderStatus(kycId, kycStatus, resetForm) ? (
          renderStatus(dbUser, setResetForm, setDidMountVeriff)
        ) : (
          <div>{renderKycRoot()}</div>
        )}
      </Panel>
    </div>
  )
}

export default KYC
