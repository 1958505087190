import { TokenList } from '@uniswap/token-lists'

export type PopupContent =
  | {
      txn: {
        hash: string
        success: boolean
        summary?: string
      }
    }
  | {
      listUpdate: {
        listUrl: string
        oldList: TokenList
        newList: TokenList
        auto: boolean
      }
    }

export type PopupItem = {
  key: string
  show: boolean
  content: PopupContent
  removeAfterMs: number | null
}

export type PopupList = Array<PopupItem>

export enum ApplicationModal {
  WALLET,
  SETTINGS,
  TOS,
  SELF_CLAIM,
  ADDRESS_CLAIM,
  CLAIM_POPUP,
  MENU,
  DELEGATE,
  VOTE,
}
