// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC } from 'react'
import { Provider } from 'react-redux'
import store, { persistor } from 'state/store'
import { PersistGate } from 'redux-persist/integration/react'

const StoreProvider: FC = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  )
}

export default StoreProvider
