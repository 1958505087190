import React, { FC } from 'react'
import { useModalOpen } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/types'

import styles from './index.module.scss'

const WalletModalBackground: FC = () => {
  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)

  return (
    <div
      className={
        walletModalOpen ? styles.backgroundOpen : styles.backgroundClosed
      }
    />
  )
}

export default WalletModalBackground
