export class NoStargazerProviderError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'No Stargazer provider was found on this.stargazer.'
  }
}

export class UserRejectedRequestError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'The user rejected the request.'
  }
}
