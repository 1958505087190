import React, { useState, FC, useRef, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import cls from 'classnames';
import useUser from 'hooks/useUser';
import { useWeb3ReactWithDAG } from 'hooks/useWeb3React';
import { ReactComponent as Logo } from 'assets/images/svgs/logo2.svg';
import { ReactComponent as Hamburger } from 'assets/images/svgs/hamburger.svg';
import { ReactComponent as MobileLogo } from 'assets/images/svgs/logo2.svg';
import { ReactComponent as ProfileIcon } from 'assets/images/svgs/profile.svg';
import { ReactComponent as DownArrowIcon } from 'assets/images/svgs/down-angle.svg';
import WalletModal from 'components/WalletModal';
import { useWalletModalToggle } from 'state/application/hooks';
import useOnClickOutside from 'hooks/useOnClickOutside';

import logosvg from 'assets/images/svgs/logoportalbeta.svg';
import logo from 'assets/images/pngs/logoportalbeta.png';

import Button from '../common/Button';
import config from '../../config';
import styles from './index.module.scss';
import ProfileDropdown from './ProfileDropdown';

interface IHeaderProps {
  open: boolean;
  onButtonClick?: () => void;
  beta?: boolean;
}
interface IUserState {
  authenticated: boolean;
  walletConnected: boolean | undefined | string;
}

const Header: FC<IHeaderProps> = ({ onButtonClick, beta }) => {
  const { user } = useUser();
  const { account, dagAccount } = useWeb3ReactWithDAG();
  const toggleWalletModal = useWalletModalToggle();

  const [userState, setUserState] = useState<IUserState>({
    authenticated: user?.attributes.email_verified,
    walletConnected: account || dagAccount,
  });

  useEffect(() => {
    if (user && !userState.authenticated) {
      setUserState((pS) => ({
        ...pS,
        authenticated: !!user,
      }));
    }
    if ((account || dagAccount) && !userState.walletConnected) {
      setUserState((pS) => ({
        ...pS,
        walletConnected: account || dagAccount,
      }));
    }
    if (!user && userState.authenticated) {
      setUserState((pS) => ({
        ...pS,
        authenticated: false,
      }));
    }
    if (!(account || dagAccount) && userState.walletConnected) {
      setUserState((pS) => ({
        ...pS,
        walletConnected: false,
      }));
    }
  }, [user, account, dagAccount]);
  const [dropdownShow, setDropdownShow] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLButtonElement>(null);

  const profileItemClick = (route: string) => {
    history.push(route);
    setDropdownShow(false);
  };
  const history = useHistory();
  const toggle = () => {
    onButtonClick && onButtonClick();
    toggleWalletModal();
  };

  const handleClickOutside = (e: any) => {
    if (
      profileRef.current === e.target ||
      profileRef.current?.contains(e.target)
    )
      return;
    setDropdownShow(false);
  };
  useOnClickOutside(dropdownRef, handleClickOutside);

  return (
    <div className={styles.header}>
      <NavLink to={'/'} className={styles.logo}>
        <div className={styles.mobileOnly}>
          {beta ? <img src={logosvg} alt="logo" /> : <MobileLogo />}
        </div>
        <div className={styles.tablet}>
          {beta ? <img src={logosvg} alt="logo" /> : <Logo />}
        </div>
      </NavLink>
      <div className={cls(styles.buttons, styles.tablet)}>
        {userState.walletConnected ? (
          <Button theme={'secondary'} onClick={toggle}>
            Wallet
          </Button>
        ) : (
          <Button theme={'secondary'} onClick={toggle}>
            Connect Wallet
          </Button>
        )}
        {userState.authenticated ? (
          <div className={styles.authWrapper}>
            <Button
              theme="secondary"
              variant={cls(styles.login, { [styles.active]: dropdownShow })}
              onClick={() => setDropdownShow(!dropdownShow)}
              ref={profileRef}
            >
              <ProfileIcon className={styles.profileIcon} />
              <span>My Profile</span>
              <DownArrowIcon className={styles.downArrowIcon} />
            </Button>
            {dropdownShow && (
              <ProfileDropdown
                ref={dropdownRef}
                onItemClick={(url) => profileItemClick(url)}
              />
            )}
          </div>
        ) : (
          <Button
            theme={'secondary'}
            onClick={() => history.push(config.routes.signin)}
          >
            Sign in
          </Button>
        )}
      </div>
      <div className={cls(styles.buttons, styles.mobileOnly)}>
        {userState.authenticated ? (
          <div className={styles.authWrapper}>
            <Button
              theme="secondary"
              variant={cls(styles.login, { [styles.active]: dropdownShow })}
              onClick={() => setDropdownShow(!dropdownShow)}
              ref={profileRef}
            >
              <ProfileIcon className={styles.profileIcon} />
              <span>My Profile</span>
              <DownArrowIcon className={styles.downArrowIcon} />
            </Button>
            {dropdownShow && (
              <ProfileDropdown
                ref={dropdownRef}
                onItemClick={(url) => profileItemClick(url)}
              />
            )}
          </div>
        ) : (
          <Button
            theme={'secondary'}
            onClick={() => history.push(config.routes.signin)}
          >
            Signin
          </Button>
        )}
        <div className={styles.hamburger} onClick={onButtonClick}>
          <Hamburger />
        </div>
      </div>
      <WalletModal />
    </div>
  );
};

export default Header;
