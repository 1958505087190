const log = (messageType: string, ...args: any[]): void => {
  if (process.env.NODE_ENV === 'production') return

  if (messageType === 'info') {
    console.info(...args)
  } else if (messageType === 'warn') {
    console.warn(...args)
  } else {
    console.log(...args)
  }
}

export const info = (...args: any[]): void => {
  return log('info', ...args)
}

export const warn = (...args: any[]): void => {
  return log('warn', ...args)
}
