import React, { forwardRef } from 'react'
import { Portal } from '@reach/portal'
import config from '../../config'
import styles from './index.module.scss'

interface IProps {
  onItemClick: (url: string) => void
}

// eslint-disable-next-line react/display-name
const ProfileDropdown = forwardRef<HTMLDivElement, IProps>(
  ({ onItemClick }, ref) => {
    return (
      <Portal>
        <div className={styles.dropdownWrapper} ref={ref}>
          <div
            className={styles.dropdownItem}
            onClick={() => onItemClick(config.routes.myAccount)}
          >
            My Account
          </div>
          {/* <div
            className={styles.dropdownItem}
            onClick={() => onItemClick(config.routes.kyc)}
          >
            KYC
          </div> */}
          <div
            className={styles.dropdownItem}
            onClick={() => onItemClick(config.routes.signout)}
          >
            Sign out
          </div>
        </div>
      </Portal>
    )
  }
)

export default ProfileDropdown
