import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Twitter } from 'assets/images/svgs/twitter.svg'
import { ReactComponent as Youtube } from 'assets/images/svgs/youtube.svg'
import { ReactComponent as Reddit } from 'assets/images/svgs/reddit.svg'
import { ReactComponent as Telegram } from 'assets/images/svgs/telegram.svg'
import { ReactComponent as Instagram } from 'assets/images/svgs/instagram.svg'
import config from '../../config'
import styles from './index.module.scss'

const Dot = () => <span className={styles.dot}>•</span>

const Footer: FC = () => {
  const year = new Date().getFullYear()
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <div>&copy;&nbsp;Geojam&nbsp;{year}</div>
        <div className={styles.links}>
          <div>
            &nbsp;&nbsp;
            <Dot />
            &nbsp;
            <Link to={config.routes.privacy}>Privacy Policy</Link>
          </div>
          <div>
            &nbsp;&nbsp;
            <Dot />
            &nbsp;
            <Link to={config.routes.termsOfService}>Terms of Service</Link>
          </div>
          <div>
            &nbsp;&nbsp;
            <Dot />
            &nbsp;
            <a href="mailto:support@geojam.com">Need support?</a>
          </div>
        </div>
      </div>
      <div className={styles.social}>
        <a href="https://t.me/geojamofficial" rel="noreferrer" target="_blank">
          <Telegram />
        </a>
        <a
          href="https://www.youtube.com/channel/UCHkLyB-ZJ4LSDVND1FcQjOA"
          rel="noreferrer"
          target="_blank"
        >
          <Youtube />
        </a>
        <a
          href="https://twitter.com/geojamofficial"
          rel="noreferrer"
          target="_blank"
        >
          <Twitter />
        </a>
        {/* <a href="#" rel="noreferrer" target="_blank">
          <Reddit />
        </a> */}
        <a
          href="https://www.instagram.com/geojamofficial"
          rel="noreferrer"
          target="_blank"
        >
          <Instagram />
        </a>
      </div>
    </div>
  )
}

export default Footer
