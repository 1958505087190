import { SUPPORTED_WALLETS, EthereumWalletsLink } from 'constants/index';

import React, { FC, useState, useEffect } from 'react';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { UnsupportedChainIdError } from '@web3-react/core';
import { useWeb3ReactWithDAG } from 'hooks/useWeb3React';
import { StargazerConnector } from 'connectors/Stargazer';
import Panel from 'components/common/Panel';
import Link from 'components/common/Link';
import AccountView from 'components/AccountView';
import { isMobile } from 'react-device-detect';
import { fortmatic } from 'connectors/index';
import { OVERLAY_READY } from 'connectors/Fortmatic';
import { useModalOpen, useWalletModalToggle } from 'state/application/hooks';
import { ApplicationModal } from 'state/application/types';
import { ReactComponent as WalletIcon } from 'assets/images/svgs/wallet.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/images/svgs/left-arrow.svg';
import usePrevious from 'hooks/usePrevious';

import Option from './Option';
import { WALLET_VIEWS } from './consts';
import styles from './index.module.scss';
import PendingView from './PendingView';

const WalletModal: FC = () => {
  const { active, account, dagAccount, connector, activate, error } =
    useWeb3ReactWithDAG();

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET);
  const toggleWalletModal = useWalletModalToggle();

  const previousAccount = usePrevious(account);
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);

  const [pendingWallet, setPendingWallet] = useState<
    AbstractConnector | undefined
  >();

  const [pendingError, setPendingError] = useState<boolean>();

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal();
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen]);

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [walletModalOpen]);

  // close modal when a connection is successful
  useEffect(() => {
    if (
      walletModalOpen &&
      ((active && !activePrevious) ||
        (connector && connector !== connectorPrevious && !error))
    ) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [
    setWalletView,
    active,
    error,
    connector,
    walletModalOpen,
    activePrevious,
    connectorPrevious,
  ]);

  // close wallet modal if fortmatic modal is active
  useEffect(() => {
    fortmatic.on(OVERLAY_READY, () => {
      toggleWalletModal();
    });
  }, [toggleWalletModal]);

  const closeAllConnections = async () => {
    const wallets = Object.values(SUPPORTED_WALLETS);
    for (const wallet of wallets) {
      if (wallet.connector && wallet.connector?.hasOwnProperty('close')) {
        await (wallet.connector as any).close();
      }
    }

    // Clear localstorage on load so we don't end up with stale connection details
    window.localStorage.removeItem('walletconnect');
    window.localStorage.removeItem(
      '-walletlink:https://www.walletlink.org:session:id'
    );
    window.localStorage.removeItem(
      '-walletlink:https://www.walletlink.org:session:secret'
    );
  };

  useEffect(() => {
    (async () => {
      await closeAllConnections();
    })();
  }, []);

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    setPendingWallet(connector); // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING);

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector && connector instanceof WalletConnectConnector) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
      activate(connector, undefined, false)
        .catch((error) => {
          if (error instanceof UnsupportedChainIdError) {
            activate(connector); // a little janky...can't use setError because the connector isn't set
          } else {
            setPendingError(true);
          }
        })
        .then(() => {
          if (connector && connector instanceof StargazerConnector) {
            if (
              (connector as StargazerConnector).constellationAccounts.length
            ) {
              setWalletView(WALLET_VIEWS.ACCOUNT);
            }
          }
        });
  };
  const getOptions = () => {
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key];

      if ((isMobile && !option.mobile) || (!isMobile && option.mobileOnly)) {
        return null;
      }

      const icon = option.iconName
        ? require('../../assets/images/wallets/' + option.iconName)
        : null;

      const shouldShowLink =
        (option.showLink && option.showLink()) ||
        (!option.hasOwnProperty('showLink') && option.href);

      const onOptionClick =
        isMobile && option.mobile
          ? () => {
              option.connector !== connector &&
                !shouldShowLink &&
                tryActivation(option.connector);
            }
          : () => {
              option.connector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !shouldShowLink && tryActivation(option.connector);

              return true;
            };

      return (
        <Option
          onClick={onOptionClick}
          key={key}
          data-cy={`wallet-${key.toLocaleLowerCase()}`}
          active={option.connector === connector}
          link={shouldShowLink ? option.href : undefined}
          header={option.name}
          subheader={option.getSubHeader ? option.getSubHeader() : null}
          icon={icon || ''}
        />
      );
    });
  };

  const getModalContent = () => {
    if (error && !error.message.includes('Invariant failed')) {
      return (
        <>
          <span>
            {error instanceof UnsupportedChainIdError
              ? 'Wrong Network. '
              : 'Error connecting. '}
          </span>
          <span>
            {error instanceof UnsupportedChainIdError ? (
              <h5>Please connect to the appropriate Ethereum network.</h5>
            ) : (
              'Try refreshing the page.'
            )}
          </span>
        </>
      );
    }

    if ((account || dagAccount) && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountView openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)} />
      );
    }

    return (
      <>
        {walletView === WALLET_VIEWS.PENDING ? (
          <PendingView
            connector={pendingWallet}
            error={pendingError}
            setPendingError={setPendingError}
            tryActivation={tryActivation}
          />
        ) : (
          getOptions()
        )}
        {walletView !== WALLET_VIEWS.PENDING && (
          <span className={styles.new}>
            New to Ethereum?
            <Link href={EthereumWalletsLink} variant={styles.link}>
              Learn more about wallets
            </Link>
          </span>
        )}
      </>
    );
  };

  const backAvailable = !error && walletView !== WALLET_VIEWS.ACCOUNT;

  return (
    <Panel
      Icon={backAvailable ? LeftArrowIcon : WalletIcon}
      onIconClick={() => {
        if (backAvailable) {
          setPendingError(false);
          setWalletView(WALLET_VIEWS.ACCOUNT);
        }
      }}
      title={
        backAvailable
          ? ''
          : account && walletView === WALLET_VIEWS.ACCOUNT
          ? 'Wallet'
          : 'Connect to a wallet'
      }
      variant={styles.modal}
      open={walletModalOpen}
      onClose={toggleWalletModal}
    >
      {getModalContent()}
    </Panel>
  );
};

export default WalletModal;
